import { memo, useContext, useState, useEffect, createRef } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Button, Modal, Select, Divider, Typography, Form, Checkbox, List, Alert, Progress, Space } from 'antd';
import { DownloadOutlined, InfoCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { APIRequest, isBadResponse, getExportFormats, prepareDonwload, donwload } from '../models/APIRequest';
import { axiosError, objectError, formError } from './Notifications';
import LoadingBlock from './LoadingBlock';
import { convertFileSizeToHuman } from '../utils/FileUtils';

const DownloadModal = (props) => {
  const TAG = 'DownloadModal'

  //Template
  const { t } = useTranslation()
  const { Paragraph, Text } = Typography
  const widgetType = props?.widgetType
  const widgetProps = props?.widgetProps
  const files = props?.files
  const share = props?.share
  const formRef = createRef()
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isLoaded: false,
    isSubmitting: false,
    isDownloading: false,
    downloadFilename: null,
    formValues: {},
    exportFormats: [],
    termsOfUse: [],
    cantDownload: [],
    acceptTerms: false
  }
  const DEFAULT_DOWNLOAD_PROGRESS = {
    loadedPercentage: 0,
    loaded: null,
    total: null,
    speed: null
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [ downloadProgress, setDownloadProgress ] = useState(DEFAULT_DOWNLOAD_PROGRESS)

  //Hooks
  useEffect(() => {
    if (!files) {
      return
    }

    loadData()
  }, [states.isModalVisible, states.isLoaded])

  useEffect(() => {
    //Check
    if (!states.isSubmitting || !states.isDownloading) {
      return
    }

    //Init
    let isDownloadingIsTrue = false
    let lastLoaded = false
    let lastDownloadProgress = moment()
    const request = APIRequest(
      authState.language,
      authState.token,
      {
        responseType: 'blob',
        onDownloadProgress: function(progressEvent) {
          //Show downloading view (only one time)
          if (!isDownloadingIsTrue) {
            setStates({
              ...states,
              isSubmitting: true,
              isDownloading: true,
            })

            isDownloadingIsTrue = true;
          }

          //Duration of process
          const duration = moment.duration(moment().diff(lastDownloadProgress)).asSeconds()

          setDownloadProgress({
            loadedPercentage: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            loaded: convertFileSizeToHuman(progressEvent.loaded),
            total: convertFileSizeToHuman(progressEvent.total),
            speed: convertFileSizeToHuman(Math.round((progressEvent.loaded - lastLoaded) / duration))
          })

          lastLoaded = progressEvent.loaded
          lastDownloadProgress = moment()
        }
      },
      0
    )

    //States
    setDownloadProgress(DEFAULT_DOWNLOAD_PROGRESS)

    //Request
    donwload(
      request,
      getFormDatas(),
      share?.id,
      share?.secureKey,
      share?.password
    ).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = downloadUrl
      link.setAttribute('download', states.downloadFilename)

      document.body.appendChild(link)

      link.click()
      link.remove()

      setTimeout(() => {
        hideModal()
      }, 750)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)

      if (thrown?.message !== 'canceled') {
        axiosError(t)
      }

      setStates(DEFAULT_STATES)
    })

    return () => {
      request.abortController.abort()
    }
  }, [states.isDownloading])

  //Hooks methods
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (!states.isModalVisible || states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    getExportFormats(APIRequest(authState.language, authState.token)).then((results) => {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true
        })

        axiosError(t)
        return
      }

      //Export format
      const exportFormats = (results.data.data.formats) ? results.data.data.formats : []

      //Get media type
      const types = []

      files.forEach((file) => {
        if (file.can_download_access && !types.some((item) => item.type === file.file_type.type)) {
          types.push(file.file_type)
        }
      })

      //Generate export form data
      const exportFormatsForm = []

      types.forEach((type) => {
        if (exportFormats[type.type]) {
          let name = 'export_format_' + type.type
          let label
          let description
          let validationMessage
          let options = []

          exportFormats[type.type].forEach((format) => {
            options.push({
              value: format.id,
              label: format.title + ((format.media_width) ? ' (' + format.media_width + 'px)' : '')
            })
          })

          if (type.type === 'image') {
            label = t('Images')
            description = t('Please select an export format for the images.')
            validationMessage = t('You must select an export format.')
          } else {
            label = 'Not set...'
            description = 'Not set...'
            validationMessage = 'Not set...'
          }

          exportFormatsForm.push({
            name: name,
            label: label,
            description: description,
            validationMessage: validationMessage,
            options: options
          })
        } else {
          console.log(TAG, 'No export format for: ' + type.type)
        }
      })

      //Generate terms of use & cant download
      const termsOfUse = []
      const cantDownload = []

      files.forEach((file) => {
        if (!file.can_download_access) {
          cantDownload.push({
            key: 'cantDownload' + file.id,
            thumb: file.thumb,
            title: file.title,
            description: file.description,
          })
        } else if (file.terms_of_use && file.terms_of_use !== '') {
          termsOfUse.push({
            key: 'termsOfUseFile' + file.id,
            thumb: file.thumb,
            title: file.title,
            terms_of_use: file.terms_of_use
          })
        }
      })

      setStates({
        ...states,
        isLoaded: true,
        exportFormats: exportFormatsForm,
        termsOfUse: termsOfUse,
        cantDownload: cantDownload,
        acceptTerms: (!termsOfUse.length),
        error: false
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true
      })
    })
  }

  const getFormDatas = () => {
    const formDatas = {}

    files.forEach((file, key) => {
      formDatas['files[' + key + ']'] = file.id
    })

    if (Object.keys(states.formValues).length) {
      Object.entries(states.formValues).forEach(format => {
        formDatas[format[0]] = format[1]
      })
    }

    return formDatas
  }

  //Template methods
  const showModal = () => {
    setStates({
      ...states,
      isModalVisible: true
    })
  }

  const hideModal = () => {
    setStates(DEFAULT_STATES)
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setStates({
      ...states,
      isSubmitting: true
    })

    prepareDonwload(
      APIRequest(authState.language, authState.token),
      getFormDatas(),
      share?.id,
      share?.secureKey,
      share?.password
    ).then(response => {
      if (isBadResponse(response.data)) {
        objectError(t)

        setStates({
          ...states,
          isSubmitting: false,
          isDownloading: false,
        })
        return
      }

      setStates({
        ...states,
        isSubmitting: true,
        isDownloading: true,
        downloadFilename: response.data.data.filename,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false,
        isDownloading: false,
      })
    })
  }

  const checkDownloadIsAvailable = (files) => {
    let test = false

    if (!files || !files.length) {
      return test
    }

    files.every(file => {
      if (file.can_download_access) {
        test = true
        
        return false
      }

      return true
    })

    return test
  }

  const isUnrestrictedAccessDownload = (files) => {
    let restrictedFiles = 0
    let unrestrictedFiles = 0

    if (!files || !files.length) {
      return false
    }

    files.forEach(file => {
      if (file.can_download_access) {
        unrestrictedFiles++;
      }
      if (file.can_download) {
        restrictedFiles++;
      }
    })

    return (unrestrictedFiles > restrictedFiles)
  }

  const termsOfUseValidation = (rule, value, callback) => {
    if (states.acceptTerms) {
      return callback()
    }

    return callback(t('Please accept the terms and conditions'))
  }

  const getExportFormatForm = () => {
    const views = []

    states.exportFormats.forEach(item => {
      views.push(
        <Form.Item
          key={item.label}
          name={item.name}
          label={item.label}
          extra={item.description}
          required={true}
          rules={[
            {required: true, message: item.validationMessage},
          ]}
        >
          <Select options={item.options} />
        </Form.Item>
      )
    })

    if (views.length) {
      return (
        <>
          <Divider>{t('Export formats')}</Divider>
          {views}
        </>
      )
    } else {
      return null
    }
  }

  const getTermsOfUse = () => {
    const views = []

    if (!states.termsOfUse) {
      return views
    }

    states.termsOfUse.forEach((file) => {
      views.push(
        <List.Item key={file.key}>
          <List.Item.Meta
            avatar={<img src={file.thumb} alt={file.title} style={{ width:60 }}  />}
            title={file.title}
            description={file.terms_of_use}
          />
        </List.Item>
      )
    })

    if (views.length) {
      return (
        <>
          <Divider>{t('Terms of use')}</Divider>
          <List>
            {views}
          </List>
          <Form.Item
            key='termsOfUse'
            name='terms_of_use'
            onChange={(e) => {
              setStates({...states, acceptTerms: e.target.checked})
            }}
            rules={[
              {validator: termsOfUseValidation},
            ]}
            style={{textAlign:'center'}}
          >
            <Checkbox>{t('I accept all terms of use')}</Checkbox>
          </Form.Item>
        </>
      )
    } else {
      return null
    }
  }

  const getCantDownload = () => {
    const views = []

    if (!states.cantDownload) {
      return views
    }

    states.cantDownload.forEach((file) => {
      views.push(
        <List.Item key={file.key}>
          <List.Item.Meta
            avatar={<img src={file.thumb} alt={file.title} style={{ width:60 }}  />}
            title={file.title}
            description={file.description}
          />
        </List.Item>
      )
    })

    if (views.length) {
      return (
        <>
          <Divider>{t('Files cannot be downloaded')}</Divider>
          <Alert type="error" message={
            <List>
              {views}
            </List>
          } />
        </>
      )
    } else {
      return null
    }
  }

  const getWidget = () => {
    let isUnrestricted = isUnrestrictedAccessDownload(files)
    if (widgetType === 'menuItem') {
      return (
        <Text onClick={() => showModal()} {...widgetProps}>
          {t('Download')}
        </Text>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={isUnrestricted ? <UnlockOutlined /> : <DownloadOutlined />}
          disabled={!files.length}
          onClick={() => showModal()}
          {...widgetProps}
        >
          {t('Download')}
        </Button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape="circle"
          icon={<DownloadOutlined />}
          disabled={!files.length}
          onClick={() => showModal()}
          {...widgetProps}
          type={isUnrestricted ? 'dashed' : widgetProps?.type}
        />
      )
    } else {
      return (<div>Widget type not found ({widgetType})</div>)
    }
  }

  const getSubmitContent = () => {
    if (!states.isSubmitting) {
      return <LoadingBlock />
    
    } else if (!states.isDownloading) {
      return <LoadingBlock text={t('Please wait, we prepare your files...')} />
    
    } else if (states.isDownloading) {
      return (
        <Space direction="vertical" align="center" style={{ width:'100%' }}>
          <Text>{t('Downloading file...')}</Text>
          <Progress type="circle" percent={downloadProgress.loadedPercentage} />
          {(downloadProgress.loaded && downloadProgress.total && downloadProgress.speed) && (
            <Text>{downloadProgress.loaded + ' / ' + downloadProgress.total + ' (' + downloadProgress.speed + '/s)'}</Text>
          )}
        </Space>
      )
    }
  }

  if (!files || !files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return
  } else if (!checkDownloadIsAvailable(files)) {
    return
  }

  return (
    <>
      {getWidget()}
      <Modal
        title={(states.isLoaded && files.length > 1) ? t('Download') + ' - ' + t('%d files').replace('%d', files.length) : t('Download')}
        open={states.isModalVisible}
        zIndex={110}
        onCancel={hideModal}
        footer={[
          <Button type="link" key="downloadNo" onClick={hideModal}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="downloadYes" disabled={states.isSubmitting} loading={states.isDownloading} onClick={() => {
            if (states.exportFormats.length || states.termsOfUse.length) {
              formRef.current.submit()
            } else {
              submitForm()
            }
          }}>
            {t('Download')}
          </Button>,
        ]}
      >
        {!states.isLoaded || states.isSubmitting ? getSubmitContent() : (
          <>
            <Paragraph>
              <InfoCircleOutlined style={{paddingRight:5}} />
              {t('No logo, graphic or image from any Sodikart media library may be used without the express written permission of Sodikart.')}
              &nbsp;
              {t('All brands, names, titles, logos, pictures, designs, texts and other materials appearing in it are the property of Sodikart.')}
            </Paragraph>
            {(states.exportFormats.length || states.termsOfUse.length) ? (
              <Form
                name="download"
                ref={formRef}
                layout="vertical"
                initialValues={states.formValues}
                onFieldsChange={(changedFields) => {
                  if (!changedFields || !changedFields.length || changedFields[0].name[0] === 'terms_of_use') {
                    return
                  }

                  setStates({...states, formValues: {...states.formValues, [changedFields[0].name[0]]: changedFields[0].value}})
                }}
                onFinish={() => submitForm()}
                onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
                autoComplete="off"
              >
                {getExportFormatForm()}
                {getTermsOfUse()}
              </Form>
            ) : null}
            {getCantDownload()}
          </>
        )}
      </Modal>
    </>
  )
}

export default memo(DownloadModal)