import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Layout, Typography, Button, List } from 'antd';
import { CloudOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { APIRequest, isBadResponse, getHome } from '../models/APIRequest';
import { Context as AuthContext } from '../context/AuthContext';
import { ModalProvider } from "../context/ModalContext";
import ApiErrorResult from '../components/ApiErrorResult';
import { axiosError } from '../components/Notifications';
import TemplateFooter from "../components/template/TemplateFooter";
import LoadingBlock from '../components/LoadingBlock';
import LangSelector from "../components/LangSelector";
import FolderBlockList from '../components/list/FolderBlockList';
import SearchInput from '../components/SearchInput';
import HeaderLogin from "../components/HeaderLogin";
import { setStorageData, getStorageData, delStorageData } from '../utils/StorageUtils';
import { nl2br } from '../utils/StringUtils';
import { useWindowDimensions } from '../utils/WindowUtils';
import { FOLDER_ROOT_ID } from '../Constant';

export default function WelcomeScreen() {
  const TAG = "WelcomeScreen"

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowWidth, windowHeight } = useWindowDimensions()
  const { Content } = Layout
  const { Title, Paragraph } = Typography

  //State
  const DEFAULT_STATES = {
    preloadStates: false, //Disabled
    error: false,
    errorData: null,

    isLoaded: false,
    welcome: null
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (!states.preloadStates) {
      return
    }

    console.log(TAG, '[]')

    processOldStates()

    return () => {
      delStorageData(TAG)
    }
  }, [])

  useEffect(() => {
    if (states.preloadStates || states.isLoaded) {
      return
    }

    console.log(TAG, '[states.preloadStates, states.isLoaded]')

    loadData()
  }, [states.preloadStates, states.isLoaded])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')

    setStates({
      ...states,
      isLoaded: false
    })

  }, [authState.language, authState.token])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const processOldStates = () => {
    console.log(TAG, 'processOldStates')

    let oldStates = getStorageData(TAG)

    if (oldStates) {
      try {
        oldStates = JSON.parse(oldStates)

        if (oldStates && (oldStates.error || !oldStates.isLoaded)) { //Use != NOT !===
          oldStates = null
        
        }
      
      } catch(thrown) {
        console.log(TAG, thrown)
        oldStates = null
      }
    } else {
      oldStates = null
    }

    if (oldStates !== null) {
      console.log(TAG, 'processOldStates: oldStates use')

      setStates({
        ...DEFAULT_STATES,
        ...oldStates,
        preloadStates: false
      })
    
    } else {
      console.log(TAG, 'processOldStates: New states')

      setStates({
        ...DEFAULT_STATES,
        preloadStates: false
      })
    }
  }

  const loadData = () => {
    console.log(TAG, 'loadData - Getting data...')

    getHome(APIRequest(authState.language, authState.token)).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true,
          errorData: results.data.data,
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        error: false,
        errorData: null,
        welcome: results.data.data
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  /*********************************************** TEMPLATE METHODS ********************************************************/
  const handleFolder = (folderId) => {
    navigate('/media/explore/' + folderId)
  }

  const onDeleteFolder = (folderId, parentFolderId) => {
    console.log(TAG, 'onDeleteFolder')

    if (!states.welcome.folders.length) {
      console.log(TAG, 'onDeleteFolder - No folders in states!')
      return
    }

    //Update states
    const folders = Object.assign([], states.welcome.folders).filter(item => item.id !== folderId)

    setStates({
      ...states,
      welcome: {
        ...states.welcome,
        folders: folders
      }
    })
  }

  const onUpdateFolder = (folderId, updatedFolder) => {
    console.log(TAG, 'onUpdateFolder (' + folderId + ')')

    if (!states.welcome.folders.length) {
      console.log(TAG, 'onUpdateFolder - No folders in states!')
      return
    }

    let folderKey = -1

    states.welcome.folders.forEach((item, key) => {

    if (item.id === folderId) {
        folderKey = key
      }
    })

    if (folderKey === -1) {
      console.log(TAG, 'onUpdateFolder - folderKey not found!')
      return
    }

    const folders = Object.assign([], states.welcome.folders)
    folders[folderKey] = updatedFolder

    setStates({
      ...states,
      welcome: {
        ...states.welcome,
        folders: folders
      }
    })
  }

  if (states.preloadStates || !states.isLoaded) {
    return (
      <LoadingBlock />
    )
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} template="fullPage" actionHandle={() => {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: false,
          error: false,
          errorData: null
        })
      }} />
    )
  }

  if (!states.welcome) {
    return null
  }

  return (
    <ModalProvider>
      <Layout>
        <div style={{ position:'absolute', top:20, right:20, display:'flex', alignItems:'center', columnGap:10 }}>
          <HeaderLogin />
          <LangSelector />
        </div>
        <div style={{ ...styles.banner, backgroundImage:'url(' + states.welcome.banner + ')', height: ((windowWidth / 16) * 6) }}>
          <div style={styles.logoContainer}>
            <img
              src="/asset/system/sodikart-logo-white.png"
              alt={t('SODIKART')}
              className="img-fluid"
            />
            <Title level={1} style={styles.title}>{t('Media library')}</Title>
          </div>
          <div className="welcome-search">
            <SearchInput onSearch={(search) => navigate('/media/search/' + search)} />
          </div>
        </div>
        <Content>
          <div style={styles.content}>
          {states.welcome.intro !== null && states.welcome.intro !== '' && (
            <Paragraph>
              {nl2br(states.welcome.intro)}
            </Paragraph>
          )}
            <Title level={3} style={{ marginBottom:20 }}>{t('Select a theme...')}</Title>
            <div className="list-center-items">
              {states.welcome.folders.length > 0 && (
                <List
                  grid={{
                    gutter: [16, 16],
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                  }}
                  dataSource={states.welcome.folders}
                  rowKey={item => item.id}
                  renderItem={item => (
                    <List.Item>
                      <FolderBlockList
                        folder={item}
                        onClick={() => handleFolder(item.id)}
                        onDeleteFolder={onDeleteFolder}
                        onUpdateFolder={onUpdateFolder}
                      />
                    </List.Item>
                  )}

                />
              )}
            </div>
            <Title level={3} style={{ marginBottom:20 }}>{t('... or')}</Title>
            <Button
              size="large"
              type="primary"
              icon={<CloudOutlined />}
              onClick={() => handleFolder(FOLDER_ROOT_ID)}
            >
              {t('Explore the media library')}
            </Button>
          </div>
        </Content>

        <TemplateFooter />
      </Layout>
    </ModalProvider>
  )
}

const styles = {
  banner: {
    backgroundSize:'cover',
    backgroundPosition:'center',
    minHeight:400,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    padding:50
  },
  logoContainer: {
    marginBottom:40
  },
  introContainer: {
    width:'100%',
    maxWidth:700,
    backgroundColor:'rgba(100, 100, 100, .6)',
    color:'#FFF',
    marginTop:40,
    padding: 15
  },
  title: {
    color:'#FFF',
    textTransform:'uppercase',
    textAlign:'center',
    fontSize:18,
    margin:'10px 0 0 0'
  },
  content: {
    padding: 20,
    textAlign:'center'
  }
}